import * as React from "react";
import { Tag } from "../../util/enpoints/types";
import { useLocalizedHelpers } from "../../hooks/useLocalizedHelpers";
import NewElement from "../elements/newElement";

type Props = {
  tagChildren: Tag[];
};

const ChildrenNavigator: React.FC<Props> = ({ tagChildren }) => {
  const { navigate } = useLocalizedHelpers();
  return (
    <div className="overflow-y-auto h-full">
      <div className="flex h-full justify-center flex-col items-center">
        <div className="flex flex-wrap flex-shrink justify-center lg:items-center">
          {tagChildren.map((elem) => (
            <NewElement key={elem.uuid} onClick={() => navigate(`/timetable/${elem.uuid}`)} text={elem.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChildrenNavigator;
