import { Transition } from "@headlessui/react";
import * as React from "react";
import { MouseEventHandler } from "react";

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
};

const NewElement: React.FC<Props> = ({ onClick, text }) => (
  <Transition
    show
    appear
    unmount={false}
    enter="transition ease-out duration-500"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-500"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    <button
      type="button"
      onClick={onClick}
      className="cursor-pointer
        transition duration-500 ease-in-out hover:bg-orange transform hover:scale-105
        flex mx-4 my-4 rounded-lg justify-center text-lg text-center
        items-center bg-gray-300 text-white h-40 w-40"
    >
      {text}
    </button>
  </Transition>
);

export default NewElement;
