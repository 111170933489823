import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useLocalization } from "gatsby-theme-i18n";
import Seo from "../components/seo";
import StandardToaster from "../components/standardToaster";
import NavBar from "../components/navBar";
import ChildrenNavigator from "../components/public-timetable/children-naviagtor";
import { Tag } from "../util/enpoints/types";
import { getChildrenTags } from "../util/enpoints";
import { createUUID } from "../util/uuid";

const Timetable: React.FC = () => {
  const { locale } = useLocalization();
  const { t } = useTranslation();
  const [tagChildren, setTagChildren] = useState<Tag[]>([]);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    let cleanup = false;
    const toastId = createUUID();

    async function getUpdatedData(): Promise<void> {
      try {
        const resChildren = await getChildrenTags("", locale, cancelToken.token);
        if (cleanup) return;
        console.log("new data set");
        setTagChildren(resChildren.data);
      } catch (e) {
        if (e instanceof axios.Cancel) {
          toast.dismiss(toastId);
          console.log(e);
          return;
        }
        if (axios.isAxiosError(e)) {
          for (const errMsg of e.response.data.errors) {
            toast.error(errMsg);
          }
        } else {
          throw e;
        }
      }
    }

    toast.promise(
      getUpdatedData(),
      {
        loading: t("loading"),
        success: t("loaded"),
        error: t("loadingError"),
      },
      { id: toastId }
    );
    return () => {
      toast.dismiss(toastId);
      cleanup = true;
      cancelToken.cancel("Only one request can be performed");
    };
  }, [locale, t]);
  return (
    <>
      <Seo title={t("publicTimetables")} />
      <StandardToaster />
      <div className="flex flex-col h-full">
        <NavBar title={t("publicTimetables")} backButton={false} />
        <ChildrenNavigator tagChildren={tagChildren} />
      </div>
    </>
  );
};

export default Timetable;
